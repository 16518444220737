/**
 * The fonts available for writing on the sheet music
 */
export enum Fonts {
    TimesNewRoman,
    Kokila,
    Gonville
}

export const FontString: Object = {
    Gonville: 2,
    Kokila: 1,
    TimesNewRoman: 0
};

export const FontStringNames: Object = {
    0: "Times New Roman",
    1: "Kokila",
    2: "Gonville"
};
