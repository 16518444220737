import {TextAlignmentEnum} from "../Common/Enums/TextAlignment";
import {OSMDColor} from "../Common/DataObjects/OSMDColor";
import {Fonts, FontStringNames} from "../Common/Enums/Fonts";
import {FontStyles} from "../Common/Enums/FontStyles";

/**
 * A text label on the graphical music sheet.
 * It is used e.g. for titles, composer names, instrument names and dynamic instructions.
 */
export class Label {

    constructor(text: string = "", alignment: TextAlignmentEnum = TextAlignmentEnum.CenterBottom,
                font: Fonts = undefined, print: boolean = true) {
        this.text = text;
        this.print = print;
        this.textAlignment = alignment;
        this.font = font;
        this.fontFamily = undefined; // default value, will use EngravingRules.DefaultFontFamily at rendering
    }

    public text: string;
    public print: boolean;
    public color: OSMDColor;
    public colorDefault: string; // TODO this is Vexflow format, convert to OSMDColor. for now convenient for default colors.
    private fontEnumValue: Fonts;
    public set font(font: Fonts) {
        this.fontEnumValue = font;
        this.fontFamily = FontStringNames[font];
    }
    public get font(): Fonts {
        return this.fontEnumValue;
    }
    public fontFamily: string; // default undefined: will use EngravingRules.DefaultFontFamily at rendering
    public fontStyle: FontStyles;
    public fontHeight: number;
    public textAlignment: TextAlignmentEnum;
    public IsCreditLabel: boolean = false;

    public ToString(): string {
        return this.text;
    }
}
