/**
 * The styles available to write text on the music sheet
 */
export enum FontStyles {
    Regular = 0,
    Bold = 1,
    Italic = 2,
    BoldItalic = 3,
    Underlined = 4
}

export const FontStyleString: Object = {
    Bold: 1,
    BoldItalic: 3,
    Italic: 2,
    Regular: 0,
    Underlined: 4
};
