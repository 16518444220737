export enum PlaybackState {
    Stopped,
    Running
}

export enum MessageBoxType {
    Info,
    Warning,
    Error
}
